import { useContext } from 'react';
import { CustomiseVoucherContext } from '../components/Context/Context';
import { useAppContext } from '../lib/contextProviders/contexts/appContext';
import { B2B } from '../constants';
import { useUserContext } from '../lib/contextProviders/contexts/userContext';

const useBusinessEntityData = () => {
  const { entityData } = useContext(CustomiseVoucherContext);
  const {
    appState: { siteType },
  } = useAppContext();
  const {
    userState: { isLoggedIn, isLoggingIn },
  } = useUserContext();
  const isB2BSite = siteType === B2B;
  const isEntityDataReady = (!isLoggingIn && !isLoggedIn) || entityData !== null;
  const isUserAccHasBusinessEntity = isEntityDataReady && !!entityData?.id;

  return {
    isB2BSite,
    isEntityDataReady,
    isUserAccHasBusinessEntity,
    entityData,
  };
};

export default useBusinessEntityData;
